import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import {
  GET_BUILDING_LIST_SUCCESS,
  GET_BUILDING_LIST_FAILED,
  NETWORK_FAILED,
  PUT_CANCEL_BOOKING_SUCCESS,
  PUT_CANCEL_BOOKING_FAILED,
  GET_FLOOR_LIST_FAILED,
  GET_FLOOR_LIST_SUCCESS,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_FAILED,
  GET_BOOKING_STATUS_SUCCESS,
  GET_BOOKING_STATUS_FAILED,
  GET_LOCATION_DETAILS_SUCCESS,
  GET_LOCATION_DETAILS_FAILED,
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_LIST_FAILED,
  TOGGLE_ERROR_MODAL,
} from "../actions/index.js";
import { error_type } from "../utils/Constant";


const defaultState = {
  buildingList: [],
  locationDetails: undefined,
  locationList:[],
  idToken: null,
  accessToken: null,
  isError: false,
  error_type:error_type.Default
};

const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case NETWORK_FAILED:
      return {
        ...state,
        loading: false,
        isError: false,
        isBookingData: false,
      };

      case GET_LOCATION_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          isError: false,
          locationList: action.payload,
          isBookingData: false,
        };
        case GET_LOCATION_LIST_FAILED:
          return {
            ...state,
            loading: false,
            isError: true,
            error_type:error_type.LOCATION_NOT_AVAILABLE,
            locationList: [],
            isBookingData: false,
          };
    case PUT_CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        booking_data: undefined,
        isBookingData: false,
      };
    case PUT_CANCEL_BOOKING_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        error_type:error_type.Default,
        isBookingData: false,
      };
    case GET_BOOKING_STATUS_SUCCESS:

    
   
      return {
        ...state,
        loading: false,
        isError: false,
        booking_data: action.payload,
        isBookingData: false,
      };
    case GET_BOOKING_STATUS_FAILED:
      //igonore error alert
      return {
        ...state,
        loading: false,
        isError: false,
        booking_data: undefined,
        isBookingData: false,
      };
    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        booking_data: action.payload,
        isBookingData: true,
      };
    case POST_REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        error_type:error_type.BOOKING_FAILED,
        booking_data: undefined,
        isBookingData: true,
      };
    case GET_FLOOR_LIST_SUCCESS:
      return {
        ...state,
        floorList: action.payload,
        loading: false,
        isError: false,
        isBookingData: false,
      };
    case GET_FLOOR_LIST_FAILED:
      return {
        ...state,
        floorList: [],
        loading: false,
        isError: true,
        error_type:error_type.FLOOR_NOT_AVAILABLE,
        isBookingData: false,
      };
    case GET_BUILDING_LIST_SUCCESS:
      return {
        ...state,
        buildingList: action.payload,
        loading: false,
        isError: false,
        isBookingData: false,
      };
    case GET_BUILDING_LIST_FAILED:
      return {
        ...state,
        buildingList: [],
        loading: false,
        isError: true,
        error_type:error_type.BUILDING_NOT_AVAILABLE,
        isBookingData: false,
      };

    case GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        locationDetails: action.payload,
        loading: false,
        isAuthFailed: false,
        isBookingData: false,
      };
    case GET_LOCATION_DETAILS_FAILED:
      return {
        ...state,
        locationDetails: undefined,
        loading: false,
        isError: true,
        error_type:error_type.LOCATION_NOT_AVAILABLE,
        isBookingData: false,
      };
      case TOGGLE_ERROR_MODAL:
        return {
          ...state,
          isError: !state.isError,
        }
    default:
      return state;
  }
};

export const appReduxStore = createStore(
  rootReducer,
  applyMiddleware(thunk),
);
