import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Loading } from '@dsi/react-eds';

import './Loader.css';

const Loader = ({ show, size }) => {
  const classes = classNames('loader', {
    show,
  });

  return (
    <div className={classes}>
      <Loading size={size} />
    </div>
  );
};

Loader.defaultProps = {
  show: false,
  size: 'medium',
};

Loader.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Loader;
