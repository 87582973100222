import React, { useEffect } from "react";

import { Provider } from "react-redux";
import { appReduxStore } from "./app/reducers/index.js";
import { ThemeProvider } from "@dsi/react-eds";
import PropTypes from "prop-types";
import { HashRouter,BrowserRouter, Route, Switch } from "react-router-dom";

import RootFileRouter from './RootFileRouter'

const RootFile = ({ store }) => (


    <Provider store={store}>
       
  
          <BrowserRouter>
          <ThemeProvider>
  
            <RootFileRouter></RootFileRouter>
          </ThemeProvider>
          </BrowserRouter>
         ,
    </Provider>
  );
  
  RootFile.propTypes = {
    store: PropTypes.object.isRequired,
  }
  
  export default RootFile;


