import React, { Component } from "react";
import "../../../App.css";
import SuccessModal from "../../component/Modal/SuccessModal";

import { Card } from "@dsi/react-eds";
import moment from "moment";

class BookedView extends Component {
  constructor() {
    super();

    this.state = {
      confirmationModal: false,
    };

    this.onShow = this.onShow.bind(this);
  }

  onShow = (booking_data) => {
    this.setState({ confirmationModal: true,booking_data:booking_data });
  };

  onClose = (e, type) => {
    this.setState({ confirmationModal: false }, () => {
      if (type === "yes") {
        this.props._onCancelBookingPress && this.props._onCancelBookingPress(this.state.booking_data);
      }
    });
  };

  ordinal_suffix_of(i) {
    if(isNaN(i)) {
      return i + "";
    }
    i = parseInt(i);
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  _getFormattedDate(_dateVal) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const m = moment(_dateVal);
    const dayName = days[m.weekday()];
    const dateDig = this.ordinal_suffix_of(m.date());
    const monthName = monthNames[m.month()];
    const year = m.year();
    return `${dayName} ${dateDig} ${monthName}, ${year}`;
  }

  render() {
    const {  booking_data } = this.props.props;

    var fl_suffix_value =
      "Floor " + this.ordinal_suffix_of(booking_data.floor_number);
    var date = this._getFormattedDate(booking_data.registered_date);

  

    return (
      <div className="col-md-12">
        <div className="mt-2">
          <Card id="card-1" title={``}>
          <div className="" style={{textAlign:"right"   }}>
            <i className="syspanel-close icon icon-cross"  onClick={(e) => this.onShow(booking_data)}></i>
            </div>
            <label className="text-justify">
              {" "}
              {`You have booked a desk in`}
              <label style={{ fontWeight: 700 }}>
                {booking_data.building_name},&nbsp;{fl_suffix_value}
                {booking_data.zone_name.length > 0
                  ? `, ${booking_data.zone_name} for ${date}.`
                  : ` for ${date}.`}
              </label>
            </label>
            
          </Card>

  

    
        </div>
        <SuccessModal
          name={"Yes"}
          name2={"No"}
          isTwoButton={true}
          show={this.state.confirmationModal}
          message={"Are you sure you want to cancel this booking?"}
          onClose={this.onClose}
        ></SuccessModal>
      </div>
    );
  }
}

export default BookedView;
