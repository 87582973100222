import React,{useContext} from "react";

import { AppContainer, AppBody, Card } from "@dsi/react-eds";
import { connect } from "react-redux";
import * as actionCreators from "../actions/index.js";
import url from "url";

import {
  error_type,
  getErrorMessage,
  getTodaysDateLocal,
  getTodaysDateUTC,
  local_theme,
} from "../utils/Constant";

import DashBoard from "../screens/dashboard/DashBoard";
import Zones from "../screens/zones/Zones";
import Floor from "../screens/floor/Floor";
import BookingView from "../screens/booking/BookingView";
import BookedView from "../screens/booking/BookedView";

import Loader from "../component/Loader/Loader";
import SuccessModal from "../component/Modal/SuccessModal";
import NavHeader from "../component/Header/NavHeader";

import PrivacyPolicy from "../screens/Privacy/PrivacyPolicy";
import AppThemeChanger from "./ThemeChanger";
class MobileAppRoot extends React.Component {
  constructor() {
    super();

    const href = window.location.href || undefined;
    const parsedURL = href && url.parse(href, true).query;
 
    let name = "";
    let email = "";
    let c_code = "";
    let s_code = "";
    let app_theme = "light";
    
    if (parsedURL) {
      if (typeof parsedURL.name === "string") {
        name = parsedURL.name.trim();
      }

      if (typeof parsedURL.email === "string") {
        email = parsedURL.email.trim();
      }

      if (typeof parsedURL.country_code === "string") {
        c_code = parsedURL.country_code.trim();
      }

      if (typeof parsedURL.site_code === "string") {
        s_code = parsedURL.site_code.trim();
      }
      if (typeof parsedURL.app_theme === "string") {
        app_theme = parsedURL.app_theme.trim();
        app_theme = app_theme ==="light"|| app_theme === "dark"? app_theme : "light";

      }
   
      
    }


    localStorage.setItem(local_theme, app_theme);
  
    
    let local_today_date = getTodaysDateLocal();
    let utc_today_date = getTodaysDateUTC();

    this.state = {
      today_local_date: local_today_date,
      today_utc_date: utc_today_date,
      selected_date_local: local_today_date,
      selected_date_utc: utc_today_date,
      accountInfo: null,
      loading: true,
      isShowRoomBooked: false,
      userSettings: false,
      width: 0,
      height: 0,
      pageHeader: ["Home", "Floor", "Zone", "Booking", "Home", "Privacy"],
      user_name: name,
      profile_pic: "",
      email_id: email,
      user_location: "",
      default_welcome_text: "Welcome",
      default_you_are_booking_text: "You are booking in",
      booking_text:
        "If you want to book a seat for the next working day (Mo-Fr), you have to register after",
      default_you_are_text: "You are in",
      default_where_you_want_to_go_text: "When do you want to go?",
      page_number: 0,
      selected_region_index: -1,
      selected_building_index: -1,
      selected_floor_index: -1,
      last_page: 0,
      country_code: c_code,
      sites_code: s_code,
      buildingList: [],
      floorList: [],
      regionList: [],
      locationList: [],
    };

    this._buildingSelectionClick = this._buildingSelectionClick.bind(this);
    this._roomSelectionClick = this._roomSelectionClick.bind(this);
    this._regionSelectionClick = this._regionSelectionClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this._onBackPress = this._onBackPress.bind(this);
    this._onHomePress = this._onHomePress.bind(this);
    this._onBookDesk = this._onBookDesk.bind(this);
    this._onCloseSuccessBookModal = this._onCloseSuccessBookModal.bind(this);
    this._onCancelBookingPress = this._onCancelBookingPress.bind(this);
    this.togglePrivacyPolicy = this.togglePrivacyPolicy.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._closeErrorModal = this._closeErrorModal.bind(this);
  }

  render() {
    const { user_location, sites_code, country_code } = this.state;
    var location_name = "";

    if (
      user_location !== undefined &&
      user_location !== null &&
      user_location.length > 0
    ) {
      location_name = user_location;
    } else {
      location_name = country_code + sites_code;
    }

    var isTwoButtonErrorModal = false;

    return (
      <>
        <AppContainer>
          <NavHeader
    props={this.state}
    _onHomePress={this._onHomePress}
    _onBackPress={this._onBackPress}
    />
          <AppBody hideNavigation={true}>
            {this.state.loading ? (
              <Loader show />
            ) : this.props.isError &&
              !isTwoButtonErrorModal &&
              this.props.error_type === error_type.LOCATION_NOT_AVAILABLE ? (
              <div className="col-md-12">
                <Card title={""}>
                  <label className="text-justify">
                    {`Desk Booking App not configured for ${location_name}, you can change site from Dashboard hamburger menu.`}
                  </label>
                </Card>
              </div>
            ) : (
              this.getPageToLoad()
            )}
            <AppThemeChanger />
          </AppBody>
        </AppContainer>

        {this.props.isError && isTwoButtonErrorModal ? (
          <SuccessModal
            name={"Yes"}
            name2={"No"}
            isTwoButton={true}
            show={this.props.isError}
            message={getErrorMessage(this.props.error_type)}
            onClose={this._closeErrorModal}
          />
        ) : null}

        {this.props.isError &&
        !isTwoButtonErrorModal &&
        this.props.error_type !== error_type.LOCATION_NOT_AVAILABLE ? (
          <SuccessModal
            name={"Ok"}
            isTwoButton={false}
            show={this.props.isError}
            message={getErrorMessage(this.props.error_type)}
            onClose={this._closeErrorModal}
          />
        ) : null}
      </>
    );
  }

  togglePrivacyPolicy() {
    if (this.state.page_number !== 5) {
      this.setState({ page_number: 5, last_page: this.state.page_number });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.isBookingData === true) {
      // after successful booking  props.booking_data will come as object so we need to call  this._getBookingdata() to get all the booking data for the next 7 day
      if (
        props.booking_data !== undefined &&
        props.booking_data.status === "CONFIRMED"
      ) {
        this._getBookingData();
      }
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    if (this.state.user_location.length === 0) {
      this._getBookingData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getPageToLoad() {
    switch (this.state.page_number) {
      default:
      case 0:
        return (
          <DashBoard
            props={this.state}
            _buildingSelectionClick={this._buildingSelectionClick}
            _onCancelBookingPress={this._onCancelBookingPress}
            _onDateChange={this._onDateChange}
          ></DashBoard>
        );
      case 1:
        return (
          <Floor
            props={this.state}
            _onHomePress={this._onHomePress}
            _onBackPress={this._onBackPress}
            _roomSelectionClick={this._roomSelectionClick}
          ></Floor>
        );

      case 2:
        return (
          <Zones
            props={this.state}
            _onHomePress={this._onHomePress}
            _onBackPress={this._onBackPress}
            _regionSelectionClick={this._regionSelectionClick}
          ></Zones>
        );
      case 3:
        return (
          <BookingView
            props={this.state}
            _onHomePress={this._onHomePress}
            _onBackPress={this._onBackPress}
            _onBookDesk={this._onBookDesk}
            onCloseSuccessBookModal={this._onCloseSuccessBookModal}
          ></BookingView>
        );
      case 4:
        return (
          <BookedView
            props={this.state}
            _onCancelBookingPress={this._onCancelBookingPress}
          ></BookedView>
        );

      case 5:
        return <PrivacyPolicy></PrivacyPolicy>;
    }
  }

  _closeErrorModal() {
    this.props.toggleErrorModal();
    this.setState({ loading: false, page_number: 0 });
  }
  //yyyy-mm-dd
  _onDateChange(local_date, utc_date) {
    this.setState(
      {
        loading: true,
        selected_date_local: local_date,
        selected_date_utc: utc_date,
      },
      () => {
        this._getAllBuildingList();
      }
    );
  }
  _onCancelBookingPress(bookingData) {
    this.setState({ loading: true }, () => {
      this.props.putCancelBooking(bookingData.id).then(() => {
        this._getBookingData();
      });
    });
  }
  _onBackPress() {
    const {
      last_page,
      page_number,
      floorList,
      selected_floor_index,
    } = this.state;

    if (page_number === 5) {
      this.setState({ page_number: last_page });
    } else {
      if (
        page_number === 3 &&
        floorList[selected_floor_index].is_zone_available === "false"
      ) {
        this.setState({ page_number: 1 });
      } else {
        this.setState({ page_number: this.state.page_number - 1 });
      }
    }
  }

  _onHomePress() {
    if (this.state.page_number === 5) {
      //this.props.booking_data.status === "CONFIRMED"
    } else {
      this.setState({ page_number: 0 });
    }
  }

  _buildingSelectionClick(index, lastSelected, e) {
    var valueArr = [...this.state.buildingList];
    if (lastSelected >= 0) {
      valueArr[lastSelected].isClicked = false;
    }

    valueArr[index].isClicked = !valueArr[index].isClicked;

    this.setState(
      { loading: true, selected_building_index: index, buildingList: valueArr },
      () => this._getFloorList()
    );
  }

  _roomSelectionClick(index, lastSelected, e) {
    var valueArr = [...this.state.floorList];
    if (lastSelected >= 0) {
      valueArr[lastSelected].isClicked = false;
    }

    valueArr[index].isClicked = !valueArr[index].isClicked;
    if (valueArr[index].is_zone_available === "true") {
      this.setState({
        page_number: 2,
        selected_floor_index: index,
        floorList: valueArr,
        regionList: valueArr[index].zones,
      });
    } else {
      this.setState({
        page_number: 3,
        selected_floor_index: index,
        floorList: valueArr,
        regionList: valueArr[index].zones,
      });
    }
  }

  _regionSelectionClick(index, lastSelected, e) {
    var valueArr = [...this.state.regionList];
    if (lastSelected >= 0) {
      valueArr[lastSelected].isClicked = false;
    }

    valueArr[index].isClicked = !valueArr[index].isClicked;

    this.setState({
      page_number: 3,
      selected_region_index: index,
      regionList: valueArr,
    });
  }

  _onBookDesk = () => {
    this.setState({ loading: true });
    this.props
      .postRegisterUser({
        email_id: this.state.email_id,
        user_name: this.state.user_name,
        location_code: this.state.country_code + this.state.sites_code,
        building_code: this.state.buildingList[
          this.state.selected_building_index
        ].building_code,
        building_name: this.state.buildingList[
          this.state.selected_building_index
        ].building_name,
        floor_number: this.state.floorList[this.state.selected_floor_index]
          .floor_number,
        zone_name:
          this.state.floorList[this.state.selected_floor_index]
            .is_zone_available === "true"
            ? this.state.regionList[this.state.selected_region_index].zone_name
            : "",
        selected_date_local: this.state.selected_date_local,
        system_local_date:this.state.selected_date_local,
        site_capacity : this.state.buildingList[
          this.state.selected_building_index
        ].seat_capacity,
      })
      .then(() => {});
  };

  _onCloseSuccessBookModal = (e) => {
    this.setState({ isShowRoomBooked: false, page_number: 4 });
  };

  convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  _callLogin() {
    this.mSRef.current.login();
  }

  _getBookingData() {
    this.props
      .getBookingStatus(this.state.email_id, this.state.today_utc_date)
      .then(() => {
        this.setState(
          {
            booking_data: this.props.booking_data,
          },
          () => {
            this._getAllBuildingList();
          }
        );
      });
  }

  _getAllBuildingList() {


    this.props
      .getLocationDetails(this.state.country_code + this.state.sites_code)
      .then(() => {
        if (!this.props.isError) {
        
          
          this.setState(
            {
              locationDetails: this.props.locationDetails,
              user_location: this.props.locationDetails.location_name,
            },
            () => {
              this.props
                .getBuildingList(
                  this.state.country_code,
                  this.state.sites_code,
                  this.state.selected_date_utc
                )
                .then(() => {
                  if (!this.props.isError) {
                    this.setState({
                      loading: false,
                      buildingList: this.props.buildingList,
                      page_number: 0,
                    });
                  } else {
                    this.setState({ loading: false });
                  }
                });
            }
          );
        } else {
          this.setState({ loading: false });
        }
      });
  }

  _getFloorList() {
    this.props
      .getFloorList(
        this.state.country_code,
        this.state.sites_code,
        this.state.buildingList[this.state.selected_building_index]
          .building_code,
        this.state.selected_date_utc
      )
      .then(() => {
        if (!this.props.isError) {
          const sorted = [...this.props.floorList].sort(
            (a, b) => parseInt(a.floor_number) - parseInt(b.floor_number)
          );

          this.setState({ loading: false, floorList: sorted, page_number: 1 });
        } else {
          this.setState({ loading: false });
        }
      });
  }

}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, actionCreators)(MobileAppRoot);
