import React from "react";
import "./modal.css";
import PropTypes from "prop-types";
import { Dialog, Button} from "@dsi/react-eds";

export default class SuccessModal extends React.Component {
  onClose = (e, type) => {
    this.props.onClose && this.props.onClose(e, type);
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <>
        {this.props.isTwoButton ? (
          <Dialog
            show={this.props.show}
            warning
            title={this.props.message}
            bottom={
              <Button warning onClick={(e) => this.onClose(e, "no")}>
                {this.props.name2}
              </Button>
            }
            onClose={(e) => this.onClose(e, "yes")}
            closeButtonLabel={this.props.name}
          ></Dialog>
        ) : (
          <Dialog
          show={this.props.show}
          warning
          title={this.props.message}
          onClose={() => this.onClose()}
          closeButtonLabel={this.props.name}
        ></Dialog>
        )}
      </>
    );
  }
}

SuccessModal.propTypes = {
  show: PropTypes.bool,
};
