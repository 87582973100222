import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { appReduxStore } from "./app/reducers/index.js";
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@dsi/react-eds';
import RootFile from './RootFile'
ReactDOM.render(
  <RootFile store={appReduxStore} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
