import React from "react";
import {
  SystemBar,
  SystemBarComponent,
  AppContainer,
  AppBody,
  Card,
} from "@dsi/react-eds";

import MicrosoftLogin from "./app/auth/custome";
import { connect } from "react-redux";
import * as actionCreators from "./app/actions/index.js";
import { ACCESS_TOKEN, IS_LOGGED_IN } from "./app/actions/index.js";
import { myworkplace_url } from "./app/actions/index.js";
import DashBoard from "./app/screens/dashboard/DashBoard";
import Zones from "./app/screens/zones/Zones";
import Floor from "./app/screens/floor/Floor";
import BookingView from "./app/screens/booking/BookingView";
import BookedView from "./app/screens/booking/BookedView";

import SysPanel from "./app/component/SysPanel/SysPanel";
import Loader from "./app/component/Loader/Loader";
import SignIn from "./app/auth/microsoft/SignIn";

import NavHeader from "./app/component/Header/NavHeader";

import InstallPWA from "./app/component/PWA/InstallPWA";

import SuccessModal from "./app/component/Modal/SuccessModal";
import {
  show_log_in_loader,
  error_type,
  selected_location,
  getErrorMessage,
  getTodaysDateLocal,
  getTodaysDateUTC,
} from "./app/utils/Constant";

import {
  getUserDetails,
  getUserPhoto,
} from "./app/auth/microsoft/GraphService";

import PrivacyPolicy from "./app/screens/Privacy/PrivacyPolicy";
// footer-container
class App extends React.Component {
  constructor() {
    super();

    let pageNumber = localStorage.getItem(IS_LOGGED_IN) === "true" ? 0 : -1;

    let local_today_date = getTodaysDateLocal();
    let utc_today_date = getTodaysDateUTC();

    this.state = {
      today_local_date: local_today_date,
      today_utc_date: utc_today_date,
      selected_date_local: local_today_date,
      selected_date_utc: utc_today_date,
      accountInfo: null,
      loading: true,
      userSettings: false,
      width: 0,
      height: 0,
      pageHeader: ["Home", "Floor", "Zone", "Booking", "Home", "Privacy"],
      user_name: "",
      profile_pic: "",
      email_id: "",
      user_location: "",
      default_welcome_text: "Welcome",
      booking_text:
        "If you want to book a seat for the next working day (Mo-Fr), you have to register after",
      default_you_are_text: "You are in",
      default_you_are_booking_text: "You are booking in",
      default_where_you_want_to_go_text: "When do you want to go?",
      futre_dates_mesage: "Would You Like To Book For Future Dates?",
      page_number: pageNumber,
      selected_region_index: -1,
      selected_building_index: -1,
      selected_floor_index: -1,
      last_page: pageNumber,
      buildingList: [],
      floorList: [],
      regionList: [],
      locationList: [],
    };

    this.mSRef = React.createRef();
    this._buildingSelectionClick = this._buildingSelectionClick.bind(this);
    this._roomSelectionClick = this._roomSelectionClick.bind(this);
    this._regionSelectionClick = this._regionSelectionClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this._onBackPress = this._onBackPress.bind(this);
    this._onHomePress = this._onHomePress.bind(this);
    this._onBookDesk = this._onBookDesk.bind(this);
    this._onCloseSuccessBookModal = this._onCloseSuccessBookModal.bind(this);
    this._onCancelBookingPress = this._onCancelBookingPress.bind(this);
    this._callLogin = this._callLogin.bind(this);
    this.togglePrivacyPolicy = this.togglePrivacyPolicy.bind(this);
    this._onChangeLocation = this._onChangeLocation.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._closeErrorModal = this._closeErrorModal.bind(this);
  }


  render() {
    const ProfileImage = ({ data }) => {
      if (this.state.profile_pic.length > 0) {
        return (
          <img
            alt=""
            className="iconProfileSmall icon-profile rounded-circle"
            src={this.state.profile_pic}
          />
        );
      }

      return <i className="icon icon-profile" />;
    };

    var location_name = "";

    if (
      localStorage.getItem(selected_location) !== undefined &&
      localStorage.getItem(selected_location) !== null &&
      localStorage.getItem(selected_location).length > 0
    ) {
      location_name = JSON.parse(
        localStorage.getItem(selected_location)
      ).location_name;
    } else {
      location_name = this.state.user_location;
    }

    var isTwoButtonErrorModal = false;
    return (
      <>
      {window.open(myworkplace_url , "_self")}
        {/* <iframe src={myworkplace_url} height={window.innerHeight} width="100%"></iframe> */}
      </>

      // <>
      //   <MicrosoftLogin
      //     ref={this.mSRef}
      //     forceRedirectStrategy={true}
      //     clientId={"b2680b01-ac04-4d4b-aa30-8432130f10e7"}
      //     authCallback={this.authHandler}
      //   >
      //     {this.state.page_number === -1 ? (
      //       <>
      //         <SignIn callLogin={this._callLogin} />
      //       </>
      //     ) : (
      //       <>
      //         <SystemBar product="Desk Booking" acronym="Desk Booking">
      //           <SystemBarComponent
      //             onClick={() => this.toggleSysPanel("userSettings")}
      //           >
      //             <ProfileImage />
      //             <span className="user">{this.state.user_name}</span>
      //           </SystemBarComponent>
      //         </SystemBar>
      //         <AppContainer
      //           showSystemPanel={this.state.userSettings}
      //           systemPanels={[
      //             <SysPanel
      //               key="userPanel"
      //               hidden={!this.state.userSettings}
      //               userName={this.state.user_name}
      //               profileImage={this.state.profile_pic}
      //               onClose={() => this.toggleSysPanel("userSettings")}
      //               onLogout={() => this._logout()}
      //               locationList={this.state.locationList}
      //               userLocation={this.state.user_location}
      //               onLocationChange={this._onChangeLocation}
      //             />,
      //           ]}
      //         >
      //           <NavHeader
      //             props={this.state}
      //             _onHomePress={this._onHomePress}
      //             _onBackPress={this._onBackPress}
      //           />
      //           <AppBody hideNavigation={true}>
      //             {(this.state.loading || this.state.user_name.length === 0) &&
      //             this.state.page_number !== -1 ? (
      //               <Loader show />
      //             ) : this.props.isError &&
      //               this.state.page_number !== 5 &&
      //               !isTwoButtonErrorModal &&
      //               this.props.error_type ===
      //                 error_type.LOCATION_NOT_AVAILABLE ? (
      //               <div className="col-md-12">
      //                 <Card title={""}>
      //                   <label className="text-justify">
      //                     {`Desk Booking is not configured for ${location_name}, you can change site by clicking on your name.`}
      //                   </label>
      //                 </Card>
      //               </div>
      //             ) : (
      //               this.getPageToLoad()
      //             )}
      //             <div className="appfooter" onClick={this.togglePrivacyPolicy}>
      //               <span>Privacy Policy</span>
      //             </div>
      //           </AppBody>
      //         </AppContainer>
      //       </>
      //     )}
      //     {/* // <Loader show /> */}

      //     {this.props.isError && isTwoButtonErrorModal ? (
      //       <SuccessModal
      //         name={"Yes"}
      //         name2={"No"}
      //         isTwoButton={true}
      //         show={this.props.isError}
      //         message={getErrorMessage(this.props.error_type)}
      //         onClose={this._closeErrorModal}
      //       />
      //     ) : null}

      //     {this.props.isError &&
      //     !isTwoButtonErrorModal &&
      //     this.props.error_type !== error_type.LOCATION_NOT_AVAILABLE ? (
      //       <SuccessModal
      //         name={"Ok"}
      //         isTwoButton={false}
      //         show={this.props.isError}
      //         message={getErrorMessage(this.props.error_type)}
      //         onClose={this._closeErrorModal}
      //       />
      //     ) : null}
      //     <InstallPWA />
      //   </MicrosoftLogin>
      // </>
    );
  }

  togglePrivacyPolicy() {
    if (this.state.page_number !== 5) {
      this.setState({ page_number: 5, last_page: this.state.page_number });
    }
  }

  toggleSysPanel(value) {
    this.setState({ userSettings: !this.state.userSettings });
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.isBookingData === true) {
      // after successful booking  props.booking_data will come as object so we need to call  this._getBookingdata() to get all the booking data for the next 7 day
      if (
        props.booking_data !== undefined &&
        props.booking_data.status === "CONFIRMED"
      ) {
        this._getBookingData();
      }
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getPageToLoad() {
    if (this.state.loading) {
    }

    switch (this.state.page_number) {
      default:
      case 0:
        return (
          <DashBoard
            props={this.state}
            _buildingSelectionClick={this._buildingSelectionClick}
            _onCancelBookingPress={this._onCancelBookingPress}
            _onDateChange={this._onDateChange}
          />
        );
      case 1:
        return (
          <Floor
            props={this.state}
            _onHomePress={this._onHomePress}
            _onBackPress={this._onBackPress}
            _roomSelectionClick={this._roomSelectionClick}
          />
        );

      case 2:
        return (
          <Zones
            props={this.state}
            _onHomePress={this._onHomePress}
            _onBackPress={this._onBackPress}
            _regionSelectionClick={this._regionSelectionClick}
          />
        );
      case 3:
        return (
          <BookingView
            props={this.state}
            _onHomePress={this._onHomePress}
            _onBackPress={this._onBackPress}
            _onBookDesk={this._onBookDesk}
            onCloseSuccessBookModal={this._onCloseSuccessBookModal}
          />
        );
      case 4:
        return (
          <BookedView
            props={this.state}
            _onCancelBookingPress={this._onCancelBookingPress}
          />
        );

      case 5:
        return <PrivacyPolicy />;
    }
  }

  authHandler = (err, data) => {
    if (
      data != null &&
      (data.authResponseWithAccessToken != null ||
        data.authResponseWithAccessToken !== undefined)
    ) {
      localStorage.setItem(IS_LOGGED_IN, true);
      localStorage.setItem(show_log_in_loader, false);
      localStorage.setItem(
        ACCESS_TOKEN,
        data.authResponseWithAccessToken.accessToken
      );

      this.setState(
        { page_number: 0, loading: true, booking_data: undefined },
        () => {
          if (!this.state.locationList.length > 0) {
            this.props.getLocationList().then(() => {
              if (!this.props.isError) {
                this.setState({ locationList: this.props.locationList }, () => {
                  this._getUserProfile();
                });
              } else {
                this.setState({
                  loading: false,
                  locationList: [],
                  user_name: data.authResponseWithAccessToken.account.name,
                });
              }
            });
          }
        }
      );
    } else {
      if (localStorage.getItem(show_log_in_loader, "false") === "true") {
        localStorage.setItem(show_log_in_loader, false);
        window.location.reload();
      }
      if (localStorage.getItem(IS_LOGGED_IN) === "true") {
        this.clearCache();
        this._callLogin();
      }
    }
  };

  _closeErrorModal() {
    this.props.toggleErrorModal();
    this.setState({ loading: false, page_number: 0 });
  }
  _onCancelBookingPress(bookingData) {
    this.setState({ loading: true }, () => {
      this.props.putCancelBooking(bookingData.id).then(() => {
        this._getBookingData();
      });
    });
  }
  _onBackPress() {
    const { last_page, page_number, floorList, selected_floor_index } =
      this.state;

    if (page_number === 5) {
      this.setState({ page_number: last_page });
    } else {
      if (
        page_number === 3 &&
        floorList[selected_floor_index].is_zone_available === "false"
      ) {
        this.setState({ page_number: 1 });
      } else {
        this.setState({ page_number: this.state.page_number - 1 });
      }
    }
  }

  _onHomePress() {
    if (this.state.page_number === 5) {
      //this.props.booking_data.status === "CONFIRMED"
    } else {
      this.setState({ page_number: 0 });
    }
  }

  _buildingSelectionClick(index, lastSelected, e) {
    var valueArr = [...this.state.buildingList];
    if (lastSelected >= 0) {
      valueArr[lastSelected].isClicked = false;
    }

    valueArr[index].isClicked = !valueArr[index].isClicked;

    this.setState(
      { loading: true, selected_building_index: index, buildingList: valueArr },
      () => this._getFloorList()
    );
  }

  _roomSelectionClick(index, lastSelected, e) {
    var valueArr = [...this.state.floorList];
    if (lastSelected >= 0) {
      valueArr[lastSelected].isClicked = false;
    }

    valueArr[index].isClicked = !valueArr[index].isClicked;
    if (valueArr[index].is_zone_available === "true") {
      this.setState({
        page_number: 2,
        selected_floor_index: index,
        floorList: valueArr,
        regionList: valueArr[index].zones,
      });
    } else {
      this.setState({
        page_number: 3,
        selected_floor_index: index,
        floorList: valueArr,
        regionList: valueArr[index].zones,
      });
    }
  }

  _regionSelectionClick(index, lastSelected, e) {
    var valueArr = [...this.state.regionList];
    if (lastSelected >= 0) {
      valueArr[lastSelected].isClicked = false;
    }

    valueArr[index].isClicked = !valueArr[index].isClicked;

    this.setState({
      page_number: 3,
      selected_region_index: index,
      regionList: valueArr,
    });
  }

  _onBookDesk = () => {
    this.setState({ loading: true });
    this.props
      .postRegisterUser({
        email_id: this.state.email_id,
        user_name: this.state.user_name,
        location_code: this.state.country_code + this.state.sites_code,
        building_code:
          this.state.buildingList[this.state.selected_building_index]
            .building_code,
        building_name:
          this.state.buildingList[this.state.selected_building_index]
            .building_name,
        floor_number:
          this.state.floorList[this.state.selected_floor_index].floor_number,
        zone_name:
          this.state.floorList[this.state.selected_floor_index]
            .is_zone_available === "true"
            ? this.state.regionList[this.state.selected_region_index].zone_name
            : "",
        selected_date_local: this.state.selected_date_local,
        system_local_date: this.state.selected_date_local,
        site_capacity:
          this.state.buildingList[this.state.selected_building_index]
            .seat_capacity,
      })
      .then(() => {});
  };

  _onCloseSuccessBookModal = (e) => {
    this.setState({ isShowRoomBooked: false, page_number: 4 });
  };

  convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  _callLogin() {
    this.mSRef.current.login();
  }

  clearCache() {
    localStorage.setItem(show_log_in_loader, false);
    localStorage.setItem(IS_LOGGED_IN, false);
    localStorage.setItem(ACCESS_TOKEN, "");
  }

  relogin() {
    this.setState({ page_number: -1 });
  }

  _logout() {
    this.clearCache();
    this.mSRef.current.logout();
  }
  async _getUserProfile() {
    let user = await getUserDetails(localStorage.getItem(ACCESS_TOKEN));
    let photo = await getUserPhoto(localStorage.getItem(ACCESS_TOKEN));
    if (user === undefined) {
      this.clearCache();
      this.relogin();
      return;
    }

    const loactionArray = user.officeLocation.split(" ");

    let siteCode = loactionArray[1].replace(/\d+/g, "");
    let country_code = loactionArray[0];

 
    this.setState(
      {
        country_code: country_code,
        sites_code: siteCode,
        user_name: user.displayName,
        user_location: user.officeLocation,
        email_id: user.mail,
      },
      () => {
        this._onChangeLocation();
      }
    );
    if (photo !== undefined) {
      await this.convertBlobToBase64(photo).then((response) => {
        this.setState(
          {
            profile_pic: response,
          },
          () => {}
        );
      });
    }
    // } else {
    //   this._getBookingdata();
    // }
  }

  //yyyy-mm-dd
  _onDateChange(local_date, utc_date) {
    this.setState(
      {
        loading: true,
        selected_date_local: local_date,
        selected_date_utc: utc_date,
      },
      () => {
        this._getAllBuildingList();
      }
    );
  }

  _onChangeLocation() {
    if (this.state.loading === false) {
      this.setState({ loading: true, userSettings: false });
    }
    let siteCode;
    let country_code;
    let user_location = this.state.user_location;

    console.log("user_location", user_location)
    console.log(" JSON.parse(localStorage.getItem(selected_location))",  JSON.parse(localStorage.getItem(selected_location)))
 

    if (this.props.locationList.length > 0) {
      this.props.locationList.map((e, index) => {
        if (
          localStorage.getItem(selected_location) !== null &&
          localStorage.getItem(selected_location).length > 0
        ) {
          if (
            e.country_code ===
              JSON.parse(localStorage.getItem(selected_location))
                .country_code &&
            e.location_code ===
              JSON.parse(localStorage.getItem(selected_location)).location_code
          ) {
            e.isSelected = true;
            siteCode = e.location_code;
            country_code = e.country_code;
            user_location = e.location_name;
          } else {
            e.isSelected = false;
          }
        } else {
          if (
            e.country_code === this.state.country_code &&
            e.location_code === this.state.sites_code
          ) {
            e.isSelected = true;
            siteCode = e.location_code;
            country_code = e.country_code;
            user_location = e.location_name;
          } else {
            e.isSelected = false;
          }
        }
      });


      this.setState(
        {
          locationList: this.props.locationList,
          country_code: country_code,
          sites_code: siteCode,
          selected_region_index: -1,
          selected_building_index: -1,
          selected_floor_index: -1,
          user_location: user_location,
          page_number: 0,
        },
        () => {
          this._getBookingData();
        }
      );
    }
  }

  _getBookingData() {
    this.props
      .getBookingStatus(this.state.email_id, this.state.today_utc_date)
      .then(() => {
        this.setState(
          {
            booking_data: this.props.booking_data,
          },
          () => {
            this._getAllBuildingList();
          }
        );
      });
  }

  _getAllBuildingList() {

    console.log("in APP")
    console.log("this.state.country_code",this.state.country_code)
    console.log("this.state.sites_code",this.state.sites_code)

    this.props
      .getLocationDetails(this.state.sites_code)
      .then(() => {
        if (!this.props.isError) {
          this.setState(
            {
              locationDetails: this.props.locationDetails,
              user_location: this.props.locationDetails.location_name,
            },
            () => {
              this.props
                .getBuildingList(
                  this.state.country_code,
                  this.state.sites_code,
                  this.state.selected_date_utc
                )
                .then(() => {
                  if (!this.props.isError) {
                    this.setState({
                      loading: false,
                      buildingList: this.props.buildingList,
                      page_number: 0,
                    });
                  } else {
                    this.setState({ loading: false });
                  }
                });
            }
          );
        } else {
          this.setState({ loading: false });
        }
      });
  }

  _getFloorList() {
    this.props
      .getFloorList(
        this.state.country_code,
        this.state.sites_code,
        this.state.buildingList[this.state.selected_building_index]
          .building_code,
        this.state.selected_date_utc
      )
      .then(() => {
        if (!this.props.isError) {
          const sorted = [...this.props.floorList].sort(
            (a, b) => parseInt(a.floor_number) - parseInt(b.floor_number)
          );

          this.setState({ loading: false, floorList: sorted, page_number: 1 });
        } else {
          this.setState({ loading: false });
        }
      });
  }

}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, actionCreators)(App);
