import React, { Component } from "react";
import "../../../App.css";
import SuccessModal from "../../component/Modal/SuccessModal";
import { Button, Card, Dialog } from "@dsi/react-eds";
import { selected_location } from "../../utils/Constant";

class BookingView extends Component {
  constructor() {
    super();
    this.storage_selected_location = JSON.parse(
      localStorage.getItem(selected_location)
    );
    this.state = {
      confirmationModal: false,
      openDialogBox: false,
      errMsg: "",
    };

    this.onShow = this.onShow.bind(this);
  }
  onShow = () => {
    // this.setState({ confirmationModal: true });
    this.onClose();
  };

  onClose = (e, type) => {
    this.props._onBookDesk();
  };

  render() {
    const {
      selected_building_index,
      selected_floor_index,
      selected_region_index,
      user_location,
      default_you_are_booking_text,
      buildingList,
      floorList,
      regionList,
      isShowRoomBooked,
    } = this.props.props;

    const { onCloseSuccessBookModal } = this.props;

    var zoneName = "";
    var seatCapacity = "";

    if (floorList[selected_floor_index].is_zone_available === "true") {
      zoneName = regionList[selected_region_index].name;
      seatCapacity =
        regionList[selected_region_index].seat_available +
        "/" +
        regionList[selected_region_index].seat_capacity;
    } else {
      zoneName = "";
      seatCapacity =
        floorList[selected_floor_index].seat_available +
        "/" +
        floorList[selected_floor_index].seat_capacity;
    }
    return (
      <div className="col-md-12">
        <div className="mt-1">
          <div className="row align-items-center justify-content-center welcome-text-div">
            <div>
              <label className="welcome-text-div-text-1">
                {default_you_are_booking_text + " " + user_location},
              </label>
            </div>
            <div className="welcome-text-div-location">
              <label className="welcome-text-div-text-2">
                {" "}
                &nbsp;{buildingList[selected_building_index].name},&nbsp;
                {floorList[selected_floor_index].suffix_value}
                ,&nbsp;{zoneName}
              </label>
            </div>
          </div>
        </div>

        <Card id="card-2" title={""}>
          <div className="row align-items-center justify-content-center mt-4 mb-1">
            <label className="text-justify fontWeight700 welcome-text-div-text-2">{`${seatCapacity}`}</label>
          </div>
        </Card>

        <div className=" row align-items-center justify-content-center mt-4 mb-3">
          <Button primary onClick={this.onShow}>
            {" "}
            {"BOOK A DESK"}
          </Button>
        </div>

        <div style={{ height: "40px" }}></div>

        <SuccessModal
          name={"Yes"}
          name2={"No"}
          isTwoButton={true}
          show={this.state.confirmationModal}
          message={"Please confirm your booking"}
          onClose={this.onClose}
        ></SuccessModal>

        <SuccessModal
          name={"Ok"}
          isTwoButton={false}
          show={isShowRoomBooked}
          message={"Booking successfully completed"}
          onClose={onCloseSuccessBookModal}
        ></SuccessModal>

        <Dialog
          title="Information"
          show={this.state.openDialogBox}
          onClose={() => this.setState({ openDialogBox: false })}
        >
          <p> {this.state.errMsg}</p>
        </Dialog>
      </div>
    );
  }
}

export default BookingView;
