import React, { Component } from "react";
import "../../../App.css";
import TileButtonList from "../../component/Button/TileButton";

class Floor extends Component {
  ordinal_suffix_of(i) {
    if(isNaN(i)) {
      return i + "";
    }
    i = parseInt(i);
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  render() {
    const {
      width,
      selected_building_index,
      selected_floor_index,
      user_location,
      default_you_are_booking_text,
      buildingList,
      floorList,
    } = this.props.props;

    const {_roomSelectionClick } = this.props;

    var lastSelected = selected_floor_index;
    const listOfButton = floorList.map((e, index) => {
      e.name = "FLOOR " + e.floor_number;
      e.suffix_value = "Floor " + this.ordinal_suffix_of(e.floor_number);
      return (
        <TileButtonList
          screenWidth={width}
          lastSelected={lastSelected}
          index={index}
          key={index}
          _object={e}
          buttonClick={_roomSelectionClick}
        ></TileButtonList>
      );
    });

    return (
      <div className="col-md-12">
  
        {width > 430 ? (
          <div className="mt-1">
            <div className="row align-items-center justify-content-center welcome-text-div">
              <div>
                <label className="welcome-text-div-text-1">
                  {default_you_are_booking_text + " " + user_location},
                </label>
              </div>
              <div className="welcome-text-div-location">
                <label className="welcome-text-div-text-2">
                  {" "}
                  &nbsp;{buildingList[selected_building_index].name}
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-1 align-items-center justify-content-center">
            <div className="welcome-text-div">
              <div>
                <label className="welcome-text-div-text-1">
                  {default_you_are_booking_text + " " + user_location},
                </label>
              </div>
              <div className="welcome-text-div-location">
                <label className="welcome-text-div-text-2">
                  {buildingList[selected_building_index].name}
                </label>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-3 custome-margin-bottom align-items-center justify-content-center">
          {listOfButton}
        </div>
      </div>
    );
  }
}

export default Floor;
