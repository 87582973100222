import axios from "axios";
import UrlConfig from "./UrlConfig.json";

export const ACCESS_TOKEN = "smart-desk-app-accessToken-dev";
export const IS_LOGGED_IN = "smart-desk-app-login-dev";
export const NUMBER_OF_CALL = "isCalled-dev";

export const NETWORK_FAILED = "NETWORK_FAILED";
export const AUTH_FAILED = "500";

export const GET_LOCATION_DETAILS_SUCCESS = "GET_LOCATION_DETAILS_SUCCESS";
export const GET_LOCATION_DETAILS_FAILED = "GET_LOCATION_DETAILS_FAILED";

export const GET_LOCATION_LIST_SUCCESS = "GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_FAILED = "GET_LOCATION_LIST_FAILED";

export const GET_BUILDING_LIST_SUCCESS = "GET_BUILDING_LIST_SUCCESS";
export const GET_BUILDING_LIST_FAILED = "GET_BUILDING_LIST_FAILED";

export const GET_FLOOR_LIST_SUCCESS = "GET_FLOOR_LIST_SUCCESS";
export const GET_FLOOR_LIST_FAILED = "GET_FLOOR_LIST_FAILED";

export const GET_BOOKING_STATUS_SUCCESS = "GET_BOOKING_STATUS_SUCCESS";
export const GET_BOOKING_STATUS_FAILED = "GET_BOOKING_STATUS_FAILED";

export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILED = "POST_REGISTER_FAILED";

export const PUT_CANCEL_BOOKING_SUCCESS = "PUT_CANCEL_BOOKING_SUCCESS";
export const PUT_CANCEL_BOOKING_FAILED = "PUT_CANCEL_BOOKING_FAILED";

export const TOGGLE_ERROR_MODAL = "TOGGLE_ERROR_MODAL";

let apiUrl = process.env.REACT_APP__BOOKING;
let apiUrl_v1 = process.env.REACT_APP__BOOKING_V2;
export const myworkplace_url = process.env.REACT_APP__MYWORKPLACE;

const username = process.env.USERNAME || UrlConfig.username;
const password = process.env.PASSWORD || UrlConfig.password;

const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const axios_instance = axios.create({
  baseURL: apiUrl,
  headers: { Authorization: `Basic ${token}` },
});

const axios_instance_v1 = axios.create({
  baseURL: apiUrl_v1,
  headers: { Authorization: `Basic ${token}` },
});


export function toggleErrorModal() {
  return (dispatch) => {
    dispatch(wrapType(TOGGLE_ERROR_MODAL, ""));
  };
}

export function getLocationList() {
  return (dispatch) => {
    return axios_instance
      .get(`locations?app=desk_booking`)
      .then((response) => {
        dispatch(wrapType(GET_LOCATION_LIST_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(wrapType(GET_LOCATION_LIST_FAILED, error));
      });
  };
}

export function getLocationDetails(location) {
  return (dispatch) => {
    return axios_instance
      .get(`/locations/${location}?app=desk_booking`)
      .then((response) => {
        dispatch(wrapType(GET_LOCATION_DETAILS_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(wrapType(GET_LOCATION_DETAILS_FAILED, error));
      });
  };
}

export function getBuildingList(country, location, date) {
  return (dispatch) => {
    return axios_instance_v1
      .get(`locations/${country}${location}/buildings/capacity`, {
        params: { date: date },
      })
      .then((response) => {
        dispatch(wrapType(GET_BUILDING_LIST_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(wrapType(GET_BUILDING_LIST_FAILED, error));
      });
  };
}

export function getFloorList(country, location, building_code, date) {
  return (dispatch) => {
    return axios_instance_v1
      .get(`locations/${country}${location}/buildings/${building_code}`, {
        params: { date: date },
      })
      .then((response) => {
        dispatch(wrapType(GET_FLOOR_LIST_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(wrapType(GET_FLOOR_LIST_FAILED, error));
      });
  };
}

export function postRegisterUser(dataProps) {
  const {
    email_id,
    user_name,
    location_code,
    building_code,
    building_name,
    floor_number,
    zone_name,
    selected_date_local,
    system_local_date,
    site_capacity,
  } = dataProps;
  // TODO: Do we need this, it will be the same as selected_date_local?
  let registered_date = selected_date_local + " 00:00:00";
  let postData = {
    user_email: email_id,
    user_name,
    location_code,
    building_code,
    building_name,
    floor_number,
    zone_name,
    registered_date: registered_date,
    system_local_date,
    site_capacity
  };

  return (dispatch) => {
    return axios_instance_v1
      .post(UrlConfig.register_for_booking, postData)
      .then((response) => {
     
        if (response.data.status === "BLOCKED") {
          let id = response.data.id;
          axios_instance_v1
            .put(
              `${UrlConfig.register_for_booking}/${id}`,
              {},
              {
                auth: {
                  username: username,
                  password: password,
                },
              }
            )
            .then((response) => {
            

              dispatch(wrapType(POST_REGISTER_SUCCESS, response.data));
            })
            .catch((error) => {
              dispatch(wrapType(POST_REGISTER_FAILED, error));
            });
        } else {
          dispatch(wrapType(POST_REGISTER_FAILED, response.data));
        }
      })
      .catch((error) => {
        dispatch(wrapType(POST_REGISTER_FAILED, error));
      });
  };
}

export function getBookingStatus(email, date) {

  return (dispatch) => {
    return axios_instance_v1
      .get(UrlConfig.booking_status, { params: { 'email': email, 'date': date } })
      .then((response) => {
        dispatch(
          wrapType(GET_BOOKING_STATUS_SUCCESS, response.data)
        );
      })
      .catch((error) => {
        dispatch(wrapType(GET_BOOKING_STATUS_FAILED, error));
      });
  };
}

export function putCancelBooking(id) {
  return (dispatch) => {
    return axios_instance_v1
      .delete(UrlConfig.cancel_booking + `/${id}`, {})
      .then((response) => {
    
        dispatch(wrapType(PUT_CANCEL_BOOKING_SUCCESS, response.data));
      })
      .catch((error) => {
  
        dispatch(wrapType(PUT_CANCEL_BOOKING_FAILED, error));
      });
  };
}

export function wrapType(type, response) {
  return { type: type, payload: response };
}
