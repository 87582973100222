import moment from "moment";

export const local_theme = "smart-desk-booking-theme_v2";

export const show_log_in_loader = "smart-desk-booking-login_loader_v2";

export const selected_location = "smart-desk-booking-location_v2";

export const error_type = {
  BUILDING_NOT_AVAILABLE: "BUILDING_NOT_AVAILABLE",
  FLOOR_NOT_AVAILABLE: "FLOOR_NOT_AVAILABLE",
  ZONE_NOT_AVAILABLE: "ZONE_NOT_AVAILABLE",
  SEAT_NOT_AVAILABLE: "SEAT_NOT_AVAILABLE",
  BOOKING_FAILED: "BOOKING_FAILED",
  BOOKING_EXIST_FOR_THE_DAY: "BOOKING_EXIST_FOR_THE_DAY",
  LOCATION_NOT_AVAILABLE: "LOCATION_NOT_AVAILABLE",
  Default: "Default",
};

export function getErrorMessage(_error_type) {


  switch (_error_type) {
    case error_type.SEAT_NOT_AVAILABLE:
      return "Seats not availabe for booking";
    case error_type.BUILDING_NOT_AVAILABLE:
      return "Building not availabe for booking";
    case error_type.FLOOR_NOT_AVAILABLE:
      return "Floor not availabe for booking";
    case error_type.ZONE_NOT_AVAILABLE:
      return "Zone not availabe for booking";
    case error_type.BOOKING_FAILED:
      return "Booking failed, please try after some time";
    case error_type.BOOKING_EXIST_FOR_THE_DAY:
      return "Booking already exist for the selected date";
    case error_type.LOCATION_NOT_AVAILABLE:
      return "Location not configured for Desk Booking, please contact your local Real Estate team";
    case error_type.Default:
      return "please try after sometime";
    default:
      return "please try after sometime";
  }
}

export function getTodaysDateLocal() {
  return moment().format('YYYY-MM-DD');
}

export function getTodaysDateUTC() {
  // TODO: Clean up all use of UTC date, it was wrong and buggy! We are only interested in dates, not times, so
  //       using a date according to browsers own timezone should be fine.
  return getTodaysDateLocal();
}

export function isBookingAvailableForSelectedDate(booking_data, selected_date_local){

    let selected_date_obj = new Date(selected_date_local);
    //selected_date_obj = setTimeZero(selected_date_obj);

    let bool_val = false;
    booking_data.map((e, index) => {
      let booking_date_obj = new Date(e.system_local_date);
     // booking_date_obj = setTimeZero(booking_date_obj);

    if(booking_date_obj.getTime()===selected_date_obj.getTime())
    {
        bool_val = true;
        return bool_val;
    }

    });
    return bool_val;
}


export function setTimeZero(dateObj)
{

  dateObj.setHours(0);
  dateObj.setMinutes(0);
  dateObj.setSeconds(0);
  dateObj.setMilliseconds(0);

  return dateObj;
}
