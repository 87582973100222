import React from "react";
import { Route, Switch } from "react-router-dom";


import App from "./App";
import MobileAppRoot from "./app/MobileApp/MobileAppRoot";

function RootFileRouter() {

  return (
    <>
      <Switch>
          
          <Route path={"/"} exact={true} component={App} />
          <Route path={"/swapp"} exact={true} component={MobileAppRoot} />

          {/* Make it work also when served from sub route */}
          <Route path={"/desk-booking/"} exact={true} component={App} />
          <Route path={"/desk-booking/swapp"} exact={true} component={MobileAppRoot} />

          {/* <Route component={NotFound} exact={true} /> */}
         
        </Switch>
    </>)
}


export default RootFileRouter;