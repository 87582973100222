import React, { useState } from "react";
import "./TileButton.css";
import Evacuation from "../../image/Evacuation@3x.png";
import FirstAid from "../../image/First_Aid@3x.png";

import SuccessModal from "../Modal/SuccessModal";
import {
  getErrorMessage,
  error_type,
  isBookingAvailableForSelectedDate,
} from "../../utils/Constant";

import { Card } from "@dsi/react-eds";
import { local_theme } from "../../utils/Constant.js";

const TileButton = (props) => {
  const [isSeatNotAvailable, setSeatNotAvailable] = useState(false);
  const [errorType, setErrorType] = useState(error_type.Default);
  const {
    _object,
    index,
    lastSelected,
    buttonClick,
    booking_data,
    selected_date_local,
  } = props;

  const classNameIcon =
    localStorage.getItem(local_theme) === "dark"
      ? "iconTile"
      : "icon-invert";
  // const classNameIcon = "icon-invert";

  if (isSeatNotAvailable) {
    return (
      <SuccessModal
        name={"Ok"}
        isTwoButton={false}
        show={isSeatNotAvailable}
        message={getErrorMessage(errorType)}
        onClose={() => {
          setSeatNotAvailable(false);
        }}
      />
    );
  } else {
    return (
      <Card
        id="card-1"
        title={""}
        selected={_object.isClicked}
        onChange={(selected) => {
          if (_object.seat_available <= 0) {
            setErrorType(error_type.SEAT_NOT_AVAILABLE);
            setSeatNotAvailable(true);
          } else if (
            booking_data !== undefined &&
            booking_data !== null &&
            booking_data.length > 0 &&
            isBookingAvailableForSelectedDate(booking_data, selected_date_local)
          ) {
            setErrorType(error_type.BOOKING_EXIST_FOR_THE_DAY);
            setSeatNotAvailable(true);
          } else {
            buttonClick(index, lastSelected, selected);
          }
        }}
      >
        {_object.name}
        {_object.is_evacuator ? (
          <img src={Evacuation} alt="///" className={classNameIcon} />
        ) : null}
        {_object.is_medic ? (
          <img src={FirstAid} alt="///" className={classNameIcon} />
        ) : null}

        {_object.is_others ? (
          <i className="icon icon-avatar" style={{marginLeft:"0.5em"}} />
        ) : null}
        <span className="badge badge-light">{_object.seat_available}</span>
      </Card>
    );
  }
};

export default TileButton;
