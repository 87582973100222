import React, { Component } from "react";
import "../../../App.css";
import TileButtonList from "../../component/Button/TileButton";

class Zones extends Component {
  render() {
    const {
      width,
      selected_building_index,
      selected_floor_index,
      selected_region_index,
      user_location,
      default_you_are_booking_text,
      regionList,
      buildingList,
      floorList,
    } = this.props.props;

    const { _regionSelectionClick } = this.props;

    var lastSelected = selected_region_index;
    const listOfButton = regionList.map((e, index) => {
      e.name = e.zone_name;
      return (
        <TileButtonList
          screenWidth={width}
          lastSelected={lastSelected}
          index={index}
          key={index}
          _object={e}
          buttonClick={_regionSelectionClick}
        ></TileButtonList>
      );
    });

    return (
      <div className="col-md-12">
     
        {width > 430 ? (
          <div className="mt-1">
            <div className="row align-items-center justify-content-center welcome-text-div">
              <div>
                <label className="welcome-text-div-text-1">
                  {default_you_are_booking_text + " " + user_location},
                </label>
              </div>
              <div className="welcome-text-div-location">
                <label className="welcome-text-div-text-2">
                  {" "}
                  &nbsp;{buildingList[selected_building_index].name},&nbsp;
                  {floorList[selected_floor_index].suffix_value}
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-1 align-items-center justify-content-center">
            <div className="welcome-text-div">
              <div>
                <label className="welcome-text-div-text-1">
                  {default_you_are_booking_text + " " + user_location},
                </label>
              </div>
              <div className="welcome-text-div-location">
                <label className="welcome-text-div-text-2">
                  {buildingList[selected_building_index].name},&nbsp;
                  {floorList[selected_floor_index].suffix_value}
                </label>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-3 custome-margin-bottom align-items-center justify-content-center">
          {listOfButton}
        </div>
      </div>
    );
  }
}

export default Zones;
