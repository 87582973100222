import React, { Component } from "react";
import { Datepicker, Card } from "@dsi/react-eds";

import "../../../App.css";
import TileButtonList from "../../component/Button/TileButton";

import {setTimeZero} from '../../utils/Constant'

import BookedView from "../booking/BookedView";
import moment from "moment";

class DashBoard extends Component {
  constructor() {
    super();

    let date = new Date();

    var min = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
    var max = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate() + 7
    );
    this.state = {
      min: min,
      max: max,
      isDateWrongSelected: false,
    };
  }

  toggleWrongDateSelectedUi(boolVal) {
    this.setState({ isDateWrongSelected: boolVal });
  }



  render() {
    const {
      default_welcome_text,
      width,
      selected_building_index,
      user_location,
      user_name,
      default_you_are_text,
      default_where_you_want_to_go_text,
      futre_dates_mesage,
      buildingList,
      booking_data,
      locationDetails,
      selected_date_local,
    } = this.props.props;
    const {
      _buildingSelectionClick,
      _onCancelBookingPress,
      _onDateChange,
    } = this.props;
    var lastSelected = selected_building_index;
    const listOfButton = buildingList.map((e, index) => {
      e.name = e.building_name;
      return (
        <TileButtonList
    selected_date_local={selected_date_local}
    booking_data={booking_data}
    screenWidth={width}
    lastSelected={lastSelected}
    index={index}
    key={index}
    _object={e}
    buttonClick={_buildingSelectionClick}
    />
      );
    });

    const isBookingDataAvailable =
      booking_data !== undefined && booking_data.length > 0 ? true : false;

    if (locationDetails !== undefined && locationDetails.is_flowscape) {
      // style={{
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   height: "80vh",
      // }}
      return (
        <div className="col-md-12">
          <Card title={""}>
            <label className="text-justify">
              <p>
                Smart Office - Flowscape is used at this location, access on
                your pc{" "}
                <a href="https://internal.flowscape.ericsson.net/webapp">
                  Flowscape WebApp
                </a>{" "}
                or download Flowscape App on your phone ,{" "}
                <a href="https://ericsson.sharepoint.com/sites/DigitalWorkspace/SitePages/Smart-Office.aspx">
                  read more at digital workspace share point site
                </a>
              </p>
            </label>
          </Card>
        </div>
      );
    }
    return (
      <div className="col-md-12">
        {width > 430 ? (
          <div className="row align-items-center justify-content-center">
            <div className="welcome-text-div">
              <div>
                <label className="welcome-text-div-text-1">
                  {default_welcome_text}{" "}
                  <label className="welcome-text-div-text-2">
                    {" "}
                    &nbsp;{user_name}
                  </label>
                </label>
              </div>
              <div className="welcome-text-div-location">
                <label className="welcome-text-div-text-1">
                  {default_you_are_text}{" "}
                  <label className="welcome-text-div-text-2">
                    {" "}
                    &nbsp;{user_location}{" "}
                  </label>
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div className="row align-items-center justify-content-center">
            <div className="welcome-text-div">
              <div>
                <label className="welcome-text-div-text-1">
                  {default_welcome_text}
                </label>
                <label className="welcome-text-div-text-2">
                  {" "}
                  &nbsp;{user_name}
                </label>
              </div>
              <div className="welcome-text-div-location">
                <label className="welcome-text-div-text-1">
                  {default_you_are_text}
                </label>
                <label className="welcome-text-div-text-2">
                  {" "}
                  &nbsp;{user_location}
                </label>
              </div>
            </div>
          </div>
        )}

        {isBookingDataAvailable ? (
          <>
            <div className="row align-items-center justify-content-center">
              <div className="mt-1">
                <label>Booking Details</label>
              </div>
            </div>

            {booking_data.map((data, i) => (
              <BookedView
                key={i}
                props={{
                  booking_data: data,
                  user_name: user_name,
                  user_location: user_location,
                }}
                _onCancelBookingPress={_onCancelBookingPress}
              ></BookedView>
            ))}
          </>
        ) : null}

        <div className="row align-items-center justify-content-center">
          <div className="div-go">
            <label className="welcome-text-div-text-3">
              {isBookingDataAvailable
                ? futre_dates_mesage
                : default_where_you_want_to_go_text}
            </label>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <Datepicker
            defaultValue={selected_date_local}
            onChange={(e) => {
              const selectedDate = moment(e);
              const today = moment().startOf('day');
              if (selectedDate.isBefore(today) || selectedDate.isAfter(today.add(6, 'days'))) {

                this.toggleWrongDateSelectedUi(true);

              } else {
                
                this.toggleWrongDateSelectedUi(false);
                _onDateChange(e, e);
                
              }
            }}
          />
        </div>

        {this.state.isDateWrongSelected ? (
          <div className="row align-items-center justify-content-center mt-1 mb-3">
            <div >
              <label className="welcome-text-div-text-3 color-red">
                Booking is allowed for today and next 6 days only.
              </label>
            </div>
          </div>
        ) : (<>
          <label className="welcome-text-div-text-3 mb-2">
            Book an available desk below
          </label>
          <div className="row custome-margin-bottom align-items-center justify-content-center mt-3">
            {listOfButton}
          </div>
        </>)}
      </div>
    );
  }
}

export default DashBoard;
