import React, { useEffect, useRef, useContext } from "react";
import { local_theme } from "../utils/Constant";
import {
    ThemeContext,
  } from "@dsi/react-eds";

export default function AppThemeChanger(){
    const [theme, setTheme] = useContext(ThemeContext);
    useEffect(() => {
        if (localStorage.getItem(local_theme, "light") === "dark") {
          setTheme("dark");
        } else {
          setTheme("light");
        }
      });
      return (<></>);
}