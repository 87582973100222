import React from "react";

import "./NavHeader.css";

import econ from "../../../ericsson.png";
import backArrow from "../../image/icon_arrow_left.png";


import { local_theme } from "../../utils/Constant.js";

const NavHeader = (props) => {
  var backPageHeader;
  if(props.props.page_number===5)
  {

    backPageHeader = props.props.pageHeader[props.props.last_page];
  } else{
  if (props.props.page_number ===3 && props.props.floorList.length > 0) {
    if (
      props.props.floorList[props.props.selected_floor_index]
        .is_zone_available === "true"
    ) {
      backPageHeader = props.props.pageHeader[props.props.page_number - 1];
    } else {
      backPageHeader = props.props.pageHeader[props.props.page_number - 2];
    }
  } else {
    backPageHeader = props.props.pageHeader[props.props.page_number - 1];
  }
 }

  var classNameRightIcon;
  var classNameLeftIcon;
  var buttonTextColor;

  if (localStorage.getItem(local_theme, "light") === "dark") {
    classNameRightIcon = "iconRightInvert";
    classNameLeftIcon = "arrowLeftInvert";
    buttonTextColor = "colorAppWhite d-flex";
  } else {
    classNameRightIcon = "iconRight";
    classNameLeftIcon = "arrowLeft";
    buttonTextColor = "colorAppBlack d-flex";
  }

  return (
    <div>
      {props.props.page_number > 0 ? (
        <div className="row mt-3 marginLeft marginRight">
          <div className={"col-md-2 col-sm-2 col-2"}>
            <div
              className={buttonTextColor}
              onClick={props._onBackPress}
            >
              <img src={backArrow} alt="///" className={classNameLeftIcon} />
              <span  className="d-none d-md-block" style={{marginTop:".23em"}}>  &nbsp;&nbsp;{backPageHeader} </span>  
            </div>
          </div>
          <div  className={"col-md-8 col-sm-8 col-8"}>
            <p className="btn-border-less-2 bg-transparent"  style={{textAlign:"center"}}>
              {props.props.pageHeader[props.props.page_number]}
            </p>
          </div>
          <div className={"col-md-2 col-sm-2 text-right col-2"}>
            <div
              className="btn-border-less bg-transparent"
              onClick={props._onHomePress}
            >
              <img src={econ} alt="///" className={classNameRightIcon} />
            </div>
          </div>
        </div>
      ) : (
        <div className="row d-flex justify-content-center mt-3 marginRight marginLeft">
          <p className="btn-border-less-2 bg-transparent">
            {props.props.pageHeader[props.props.page_number]}
          </p>
        </div>
      )}
    </div>
  );
};

export default NavHeader;
